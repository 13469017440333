<template lang="pug">
    v-container(:class="{'login':!isNetwork(['rodolfistas']), 'grid-list-xl': !isNetwork(['rodolfistas']), 'ma-0 pa-0 rodolfistas-base': isNetwork(['rodolfistas'])}")
        loader(v-if="$root.loader")
        template(v-else)
            v-snackbar(
                :timeout="3000"
                :top="true"
                :right="true"
                color="error"
                v-model="error")
                    | {{ errorText }}
                    v-btn(flat dark @click.native="error = false") {{ $t('cerrar') }}
            v-snackbar(
                :timeout="3000"
                :top="true"
                :right="true"
                color="success"
                v-model="success")
                    | {{ successText }}
                    v-btn(flat dark @click.native="success = false") {{ $t('cerrar') }}
            div.full-width(v-if="!activeNetwork && !Object.keys(currentNetwork).length")
                v-layout(row wrap)
                    v-flex(xs12)
                        figure
                            img(:src="registerUrl" alt="Wappid")
                    v-flex(xs12 v-html="notfoundtext")
            template(v-else)
                template(v-if="!isNetwork(['rodolfistas'])")
                    .login-poster
                        v-layout(login-poster row wrap)
                            v-flex(xs12)
                                figure
                                    img(:src="registerUrl" alt="Wappid")
                            v-flex(v-if="activeNetwork" xs12 v-html="logintext")
                            v-flex(xs12 v-else-if="Object.keys(currentNetwork).length" v-html="currentNetwork.logintext")
                    .login-content
                        h2.secondary--text(v-if="isNetwork(['joaquingobernador'])") {{ $t('Inicia sesión') }}&nbsp;
                            template(v-if="!activeNetwork") {{ $t('en la red de apoyo') }} {{ this.currentNetwork.name }}
                        h2.secondary--text(v-else-if="isNetwork(['sumatealfr'])")
                            | {{ $t('Si ya tenes cuenta') }}&nbsp;
                            template(v-if="!activeNetwork") {{ $t('en la red de apoyo') }} {{ this.currentNetwork.name }},&nbsp;
                            | {{ $t('inicia sesión acá') }}&nbsp;
                        h2.secondary--text(v-else) {{ $t('Inicia sesión') }}&nbsp;
                            template(v-if="!activeNetwork") {{ $t('en la red') }} {{ this.currentNetwork.name }}
                        
                        v-layout(row wrap)
                            v-flex(xs12 v-if="!activeNetwork")
                                v-text-field.medium(v-if="hasMexico" v-model="form.email" :label="$t('Teléfono o correo electrónico o ID')" type="text")
                                v-text-field.medium(v-else v-model="form.email" :label="$t('Teléfono o correo electrónico')" type="text")
                            v-flex(xs12 v-else)
                                v-text-field.medium(v-model="form.email" :label="$t('Correo electrónico')" type="email")
                            v-flex(xs12)
                                v-text-field.medium(
                                    v-model="form.password" 
                                    :label="$t('Contraseña')"
                                    :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                    :append-icon-cb="() => (e1 = !e1)"
                                    :type="e1 ? 'password' : 'text'")
                        v-divider.mt-4
                        .btns.btns-center
                            v-btn(large @click.native="login" color="primary" :disabled="loadDisabled") {{ loadState }}
                            v-btn(color="secondary" outline small @click.native.stop="recoverModal = true") {{ $t('Recuperar contraseña') }}
                        v-divider.x1.primary.my-4
                        .btns.btns-center
                            v-btn(small color="secondary" :to="registerTo" v-if="isNetwork(['joaquingobernador', 'sumatealfr'])") {{ $t('Registrate acá') }}
                            v-btn(small color="secondary" :to="registerTo" v-else) {{ $t('Registrate aquí') }}
                            v-btn(small v-if="Object.keys(currentNetwork).length && currentNetwork.standregister && currentNetwork.hasstand" color="secondary" @click="$refs.stand.open(currentNetwork)")
                                v-icon.mr-1 home_filled
                                | {{ $t('Casas de registro') }}
                        v-dialog(v-model="recoverModal" max-width="600")
                            v-form
                                v-card
                                    v-card-title {{ $t('Recuperar contraseña') }}
                                    v-card-text
                                        v-layout(row wrap)
                                            v-flex(xs12)
                                                v-text-field.medium(v-model="email" :label="$t('Correo electrónico')" type="email")
                                    v-card-actions
                                        .btns.btns-right
                                            v-btn(color="secondary" small @click.native="recoverModal = false") {{ $t('Cancelar') }}
                                            v-btn(color="primary" large @click.native="restoreKey" :disabled="resDisabled") {{ resState }}
                // Landing Rodolfistas
                template(v-else)
                    landing-rodolfistas(
                        :currentNetwork="currentNetwork"
                    )
        stand-list(ref="stand")
</template>

<script> 

    import auth from 'mixins/auth'
    import StandList from 'components/stand/StandList'
    
    export default {
        mixins: [auth], 
        metaInfo (){
            return {
                title: this.$t('Inicia sesión')
            }
        },
        data() {
            return {
                e1: true,
                recoverModal: false,
                form: {
                    email: '',
                    password: '',
                    networkid: ''
                },
                error: false,
                errorText: '',
                email: '',
                networkid: '',
                success: false,
                successText: '',
                loadOptions: {
                    normal: 'Ingresar',
                    load: 'Ingresando...'
                },
                loadDisabled: false,
                loadState: '',
                resOptions: {
                    normal: 'Enviar',
                    load: 'Enviando...'
                },
                resDisabled: false,
                resState: ''
            }
        },
        computed: {

            // Existe méxico
            hasMexico(){
                return this.currentNetwork.has_mexico
            },
            registerTo(){

                if(this.$route.query.invite){
                    return '/register?invite=' + this.$route.query.invite
                }else{
                    return '/register'
                }
            }
        },
        created(){
            this.loadState = this.$t(this.loadOptions.normal)
            this.resState = this.$t(this.resOptions.normal)
        },
        methods: {

            login(){
                
                this.loadState = this.$t(this.loadOptions.load)
                this.loadDisabled = true
                
                this.$api(this, (xhr) => {
                    xhr.post('/login', this.$qs.stringify(this.form)).then((r) => {

                        let data = r.data
                        this.loadState = this.$t(this.loadOptions.normal)
                        this.loadDisabled = false
                        
                        if(!data.response){
                            this.error = true
                            this.errorText = data.message
                        }else{
                            
                            let success = data.data.hasOwnProperty('success') ? data.data.success : null
                            if(success != null){
                                return this.$router.push(this.registerTo)
                            }

                            this.$setItem('xtoken', data.data.token, () => {
                                return this.$router.push({path : this.isadmin ? '/dasboard' : '/home'})
                            })
                        }
                    }).catch(() => {})
                })
			},

            restoreKey(){

                this.resState = this.$t(this.resOptions.load)
                this.resDisabled = true

                this.$api(this, (xhr) => {
                    xhr.post('/restore-key', this.$qs.stringify({
                        email     : this.email,
                        networkid : this.networkid
                    })).then((r) => {

                        this.resState = this.$t(this.resOptions.normal)
                        this.resDisabled = false
                        
                        let data = r.data
                        if(!data.response){
                            this.error = true
                            this.errorText = data.message
                        }else{
                            this.recoverModal = false   
                            this.success = true
                            this.successText = data.message
                            this.email = ''
                        }
                    }).catch(() => {
                        this.resState = this.$t(this.resOptions.normal)
                        this.resDisabled = false
                    })
                })
            }
        },
        components: {
            'landingRodolfistas': () => import('pages/landing/rodolfistas'),
            StandList
        }
    }

</script>